.side-content  .ant-checkbox-group-item {
  width: 100%;
}

.side-content {
  margin-left: 10px;
  margin-top: 10px;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 18px;
  margin-top: 10px;
}

html, body {
  height: 100%;
}

.credit {
  margin-top: 40px;
  font-size: 12px;
}